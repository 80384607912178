exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-ideate-lab-tsx": () => import("./../../../src/pages/ideate_lab.tsx" /* webpackChunkName: "component---src-pages-ideate-lab-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index_.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-form-tsx": () => import("./../../../src/pages/investor_form.tsx" /* webpackChunkName: "component---src-pages-investor-form-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-pitch-form-tsx": () => import("./../../../src/pages/pitch-form.tsx" /* webpackChunkName: "component---src-pages-pitch-form-tsx" */),
  "component---src-pages-pitch-tsx": () => import("./../../../src/pages/pitch.tsx" /* webpackChunkName: "component---src-pages-pitch-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */)
}

